import React, { useState, useCallback } from "react";

// Modal Component
const Modal = ({ imageUrl, caption, onClose }) => (
  <div
    className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50"
    onClick={onClose}
  >
    <div
      className="relative max-w-3xl w-full bg-white rounded-lg shadow-xl overflow-hidden"
      onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
    >
      <button
        className="absolute top-4 right-4 text-white bg-black bg-opacity-60 border border-white px-4 py-2 rounded-full shadow-md transition-opacity duration-300 hover:opacity-80"
        onClick={onClose}
      >
        Close
      </button>
      <img
        src={imageUrl}
        alt="Selected"
        className="w-full h-auto max-h-screen object-cover"
      />
      <div className="absolute bottom-0 left-0 w-full bg-black bg-opacity-60 text-white text-center py-2">
        {caption}
      </div>
    </div>
  </div>
);

// ImageItem Component
const ImageItem = ({ imageUrl, caption, onViewClick }) => (
  <div className="relative group rounded overflow-hidden shadow-lg transition-transform transform hover:scale-105">
    <img
      src={imageUrl}
      alt="Gallery Item"
      className="w-full h-full object-cover"
      onClick={() => onViewClick(imageUrl, caption)}
    />
    <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-black bg-opacity-60 rounded-lg">
      <button
        className="text-white bg-transparent border border-white px-6 py-3 rounded-full shadow-md transition-transform duration-300 transform hover:scale-110"
        onClick={() => onViewClick(imageUrl, caption)}
      >
        View
      </button>
    </div>
  </div>
);

// Gallery Component
const Gallery = () => {
  const images = [
    { url: "./images/gal23.jpg", caption: "KZN IoT training Program" },
    { url: "./images/gal22.jpg", caption: "KZN IoT training Program" },
    { url: "./images/gal21.jpg", caption: "KZN IoT training Program" },
    { url: "./images/gal20.jpg", caption: "KZN IoT training Program" },
    { url: "./images/gal9.jpeg", caption: "System Support Learners" },
    { url: "./images/gal7.jpeg", caption: "System Support Learners" },
    { url: "./images/gal8.jpeg", caption: "System Support Learners" },
    { url: "./images/gal1.jpeg", caption: "2023 OMI System Development Cohort" },
    { url: "./images/gal2.jpeg", caption: "2023 OMI System Development Cohort" },
    { url: "./images/gal3.jpeg", caption: "" },
    { url: "./images/gal4.jpg", caption: "" },
    { url: "./images/gal5.jpg", caption: "" },
  ];

  const [selectedImage, setSelectedImage] = useState(null);

  const handleViewClick = useCallback((imageUrl, caption) => {
    setSelectedImage({ imageUrl, caption });
  }, []);

  const handleCloseModal = useCallback(() => {
    setSelectedImage(null);
  }, []);

  return (
    <div className="min-h-screen bg-gray-100 py-8 px-4 sm:px-6 lg:px-8">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        {images.map((image, index) => (
          <ImageItem
            key={index}
            imageUrl={image.url}
            caption={image.caption}
            onViewClick={handleViewClick}
          />
        ))}
      </div>
      {selectedImage && (
        <Modal
          imageUrl={selectedImage.imageUrl}
          caption={selectedImage.caption}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
};

export default Gallery;
