import React, { useState, useEffect } from "react";
import { courses } from "../data/Courses";
import { RiRefreshLine } from "react-icons/ri";
import { FaSearch } from "react-icons/fa";
import { useSelector } from "react-redux";
import { FaEnvelope, FaRegCalendarCheck } from "react-icons/fa";
import { Link } from "react-router-dom";

const Courses = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const baseURL = "https://ikusasatech.com/Courses/schedule/";
  const selectedTraining = useSelector(
    (state) => state.training.selectedService
  );

  useEffect(() => {
    if (selectedTraining) {
      setSelectedCategory(selectedTraining);
    }
  }, [selectedTraining]);

  const companies = Array.from(
    new Set(courses.map((course) => course.company))
  );

  const allCategories = Array.from(
    new Set(courses.flatMap((course) => course.categories))
  );

  useEffect(() => {
    setIsLoading(true);
    const timeoutId = setTimeout(() => {
      setIsLoading(false);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [searchTerm]);

  let filteredCourses = courses.filter(
    (course) =>
      course.displayName &&
      typeof course.displayName === "string" &&
      course.displayName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (selectedCategory) {
    filteredCourses = filteredCourses.filter(
      (course) =>
        course.categories && course.categories.includes(selectedCategory)
    );
  }

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const handleReset = () => {
    setSelectedCategory("");
  };

  return (
    <div className="min-h-screen bg-gray-50 py-10">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-blue-600 mb-6">Courses</h2>
        <div className="mb-8 flex flex-col md:flex-row items-center gap-4">
          <div className="relative w-full md:w-2/3">
            <input
              type="text"
              placeholder="Search for a course"
              aria-label="Search for a course"
              className="p-4 w-full border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-indigo-500 transition duration-150 ease-in-out"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <FaSearch className="absolute top-3 right-4 text-gray-500" />
          </div>
          <div className="relative w-full md:w-1/3 flex items-center">
            <select
              id="category"
              name="category"
              className="p-2 w-full border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-indigo-500 transition duration-150 ease-in-out"
              value={selectedCategory}
              onChange={handleCategoryChange}
            >
              <option value="">All Categories</option>
              {allCategories.map((category, index) => (
                <option key={index} value={category}>
                  {category}
                </option>
              ))}
            </select>
            <button
              onClick={handleReset}
              className="ml-2 p-2 border border-gray-300 rounded-lg shadow-sm hover:bg-gray-200 focus:ring-2 focus:ring-indigo-500 transition duration-150 ease-in-out"
              aria-label="Reset category filter"
            >
              <RiRefreshLine className="text-gray-500 text-lg" />
            </button>
          </div>
        </div>
        {isLoading ? (
          <p className="text-gray-600 text-lg">Loading courses...</p>
        ) : filteredCourses.length === 0 ? (
          <p className="text-gray-600 text-lg">No courses found.</p>
        ) : (
          <div>
            {companies.map((company, companyIndex) => (
              <div key={companyIndex} className="mb-8">
                <h3 className="text-2xl font-semibold text-gray-900 mb-4">
                  {company}
                </h3>
                <ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                  {filteredCourses
                    .filter((course) => course.company === company)
                    .map((course, index) => (
                      <li
                        key={index}
                        className="bg-white shadow-lg rounded-lg p-4 transition-transform duration-300 hover:scale-105 hover:shadow-xl"
                      >
                        <a
                          href={`${baseURL}${course.originalName}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="block text-lg font-semibold text-indigo-600 hover:text-indigo-800"
                        >
                          <div className="flex items-center mb-4">
                            <img
                              className={`h-20 w-20 rounded-full object-cover ${
                                course.displayName &&
                                course.displayName
                                  .toLowerCase()
                                  .includes("comptia")
                                  ? "bg-comptia"
                                  : ""
                              }`}
                              src={
                                course.imageUrl ||
                                "https://via.placeholder.com/40"
                              }
                              alt={course.displayName || "Course Image"}
                            />
                            <p className="ml-4 text-gray-800">{course.displayName}</p>
                          </div>
                          <p className="text-sm text-gray-600">
                            {course.description}
                          </p>
                        </a>
                        <div className="flex justify-between items-center mt-4">

                                {/* <a
                          href={${baseURL}${course.originalName}}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="mt-2 block text-sm font-medium text-indigo-600 hover:text-indigo-800"
                        >
                          View Training Schedule
                        </a> */}
                          <Link
                            to="/contact"
                            className="flex items-center text-blue-500 font-medium text-lg hover:text-blue-700 transition-colors"
                          >
                            <FaEnvelope className="mr-2" /> Enquire
                          </Link>
                          <Link
                            to="/enrol"
                            className="flex items-center text-blue-500 font-medium text-lg hover:text-blue-700 transition-colors"
                          >
                            <FaRegCalendarCheck className="mr-2" /> Enrol
                          </Link>
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Courses;
